import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  height: 100vh;
  position: relative;
  ${({ theme }) => theme.mq.lg} {
  }
`;

export const StyledWrapper = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 3.3rem;
`;

export const StyledPanel = styled('div')`
  display: flex;
  background: ${({ $color, theme }) => $color ?? theme.colors.green};
  padding: 1rem 1rem 1rem 3rem;
  width: 90%;
  ${({ theme }) => theme.mq.smm} {
    padding: 1rem 1rem 1rem 4rem;
    width: 70%;
  }
  ${({ theme }) => theme.mq.md} {
    padding: 1rem 1rem 1rem 6rem;
    width: 40%;
  }
`;

export const StyledTitle = styled('p')`
  font-size: 2rem;
  letter-spacing: 0.4ch;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 2.2rem;
  }
`;
