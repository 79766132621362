import styled from 'styled-components';

export const StyledWrapper = styled('div')`
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 14rem 8rem;
  gap: 6rem;
  background: ${({ backgroundColor }) => backgroundColor ?? '#FFFFFF'};
  ${({ theme }) => theme.mq.md} {
    padding: 16rem 10rem;
    flex-direction: row;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 18rem 12rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 20rem 16rem;
  }
`;

export const StyledImageRightWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 3rem;
  width: 12rem;

  ${({ theme }) => theme.mq.md} {
    top: 3.5rem;
    width: 15rem;
  }
`;

export const StyledImageLeftWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 1.5rem;
  width: 7rem;

  ${({ theme }) => theme.mq.md} {
    bottom: 2rem;
    width: 10rem;
  }
`;

export const StyledItemWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  ${({ theme }) => theme.mq.md} {
    width: 25%;
    gap: 6rem;
  }
`;

export const StyledTitle = styled('div')`
  position: relative;
  font-size: 2rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.colors.greyDark};
  ${({ theme }) => theme.mq.md} {
    font-size: 2.2rem;
    line-height: 2.4rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 2.5rem;
    line-height: 2.7rem;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 2.8rem;
    line-height: 3rem;
  }

  &:before {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    font-family: 'Montserrat', serif;

    position: absolute;
    top: -0.1rem;
    left: -2.5rem;
    content: ${({ id }) => `"${id}"`};
  }
`;

export const StyledText = styled('div')`
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.grey};
  ${({ theme }) => theme.mq.md} {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 1.8rem;
    line-height: 2rem;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 2rem;
    line-height: 2.2rem;
  }
`;
