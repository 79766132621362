import React from 'react';
import parse from 'html-react-parser';

import {
  StyledBackground,
  MainWrapper,
  StyledTitle,
  StyledText,
} from './HuecalmTemplateStyles';

const HuecalmTemplate = ({ data }) => (
  <StyledBackground
    Tag="div"
    fluid={data.background.localFile.childImageSharp.fluid}
  >
    <MainWrapper>
      <StyledTitle>{parse(data.title)}</StyledTitle>
      <StyledText>{parse(data.text)}</StyledText>
    </MainWrapper>
  </StyledBackground>
);

export default HuecalmTemplate;
