import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledInputWrapper,
  StyledLabel,
  StyledInput,
  StyledTextarea,
  StyledError,
} from './StyledInput';

const Input = ({
  label = false,
  onChange,
  onBlur,
  name,
  type,
  value,
  placeholder,
  errorMessage = false,
}) => {
  const handleInputChange = e => {
    onChange(e);
  };

  return (
    <StyledInputWrapper>
      {label ? (
        <StyledLabel htmlFor={name}>
          {label} {errorMessage && <StyledError> {errorMessage}</StyledError>}
        </StyledLabel>
      ) : (
        errorMessage && <StyledError> {errorMessage}</StyledError>
      )}

      {type === 'textarea' ? (
        <StyledTextarea
          name={name}
          rows="4"
          value={value}
          id={name}
          onChange={e => handleInputChange(e)}
          onBlur={e => onBlur(e)}
          placeholder={placeholder}
        />
      ) : (
        <StyledInput
          name={name}
          type={type}
          value={value}
          id={name}
          onChange={e => handleInputChange(e)}
          onBlur={e => onBlur(e)}
          placeholder={placeholder}
        />
      )}
    </StyledInputWrapper>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'text',
    'textarea',
    'number',
    'hidden',
    'tel',
    'date',
    'email',
    'month',
    'time',
    'week',
    'password',
    'url',
  ]).isRequired,
  value: PropTypes.string.isRequired,
};

Input.defaultProps = {
  label: null,
  errorMessage: '',
};

export default Input;
