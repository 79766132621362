import styled from 'styled-components';

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  ${({ theme }) => theme.mq.smm} {
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 50px;
  }
`;

export const StyledLabel = styled.label`
  text-align: left;
  color: ${({ theme }) => theme.colors.grey};
  font-weight: 600;
  font-size: 1.4rem;
`;

export const StyledInput = styled.input`
  border: ${({ theme }) => `1px solid ${theme.colors.greyLight}`};
  padding: 1rem 1.5rem;
  font-size: 1.6rem;
  line-height: 1.6rem;
  background-color: transparent;
  width: 100%;
  outline: none;
`;

export const StyledTextarea = styled.textarea`
  border: ${({ theme }) => `1px solid ${theme.colors.greyLight}`};
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1rem 1.5rem;
  width: 100%;
  outline: none;
  background-color: transparent;
`;

export const StyledError = styled('span')`
  display: inline-block;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.red};
  margin-left: 5px;
`;
