import React from 'react';
import parse from 'html-react-parser';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  StyledWrapper,
  StyledItemWrapper,
  StyledTitle,
  StyledText,
  StyledImageRightWrapper,
  StyledImageLeftWrapper,
} from './ThreeColumnsSectionStyles';

const ThreeColumnsSection = ({ data }) => (
  <StyledWrapper>
    {data.images && data.images[0] && (
      <StyledImageRightWrapper>
        <GatsbyImage
          image={getImage(data.images[0].localFile)}
          alt={data.images[0].alternativeText ?? 'flower'}
        />
      </StyledImageRightWrapper>
    )}
    {data.images && data.images[1] && (
      <StyledImageLeftWrapper>
        <GatsbyImage
          image={getImage(data.images[1].localFile)}
          alt={data.images[1].alternativeText ?? 'flower'}
        />
      </StyledImageLeftWrapper>
    )}

    <StyledItemWrapper>
      <StyledTitle id="1">{parse(data.title)}</StyledTitle>
      <StyledText>{parse(data.text)}</StyledText>
    </StyledItemWrapper>
    <StyledItemWrapper>
      <StyledTitle id="2">{parse(data.title2)}</StyledTitle>
      <StyledText>{parse(data.text2)}</StyledText>
    </StyledItemWrapper>
    <StyledItemWrapper>
      <StyledTitle id="3">{parse(data.title3)}</StyledTitle>
      <StyledText>{parse(data.text3)}</StyledText>
    </StyledItemWrapper>
  </StyledWrapper>
);

export default ThreeColumnsSection;
