import React, { useRef, useEffect, useState } from 'react';
import { IntlContextConsumer, useIntl } from 'gatsby-plugin-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isBrowser } from 'react-device-detect';
import { formAnimation } from 'theme/Animations';
import parse from 'html-react-parser';
import Input from '../../components/atoms/Input';
import Spinner from '../../components/FormLoader/Spinner';
import Checkbox from '../../components/atoms/Checkbox';
import Success from '../../components/FormLoader/Success';
import {
  StyledFormWrapper,
  StyledForm,
  StyledInputRow,
  StyledTitle,
  StyledSubtitle,
  StyledTitelWrapper,
  StyledSubmitBtn,
  StyledFormInnerWrapper,
  StyledBackground,
} from './FormStyles';

const Form = ({ data }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  let container = useRef(null);
  let form = useRef(null);

  const telRegExp = /^\+?[0-9]{3}-?[0-9]{6,12}$/;
  const DisplayingErrorMessagesSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, intl.formatMessage({ id: 'form_field_min' }))
      .max(50, intl.formatMessage({ id: 'form_field_max' }))
      .required(intl.formatMessage({ id: 'form_field_required' })),
    tel: Yup.string()
      .matches(telRegExp, intl.formatMessage({ id: 'form_tel_error' }))
      .required(intl.formatMessage({ id: 'form_field_required' })),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'form_email_error' }))
      .required(intl.formatMessage({ id: 'form_field_required' })),
    companyName: Yup.string().max(
      50,
      intl.formatMessage({ id: 'form_field_max' })
    ),
    message: Yup.string()
      .min(10, intl.formatMessage({ id: 'form_field_min' }))
      .max(2000, intl.formatMessage({ id: 'form_field_max' }))
      .required(intl.formatMessage({ id: 'form_field_required' })),
    rules: Yup.boolean().oneOf(
      [true],
      intl.formatMessage({ id: 'form_filed_accept' })
    ),
  });

  useEffect(() => {
    if (isBrowser) {
      formAnimation(container, form);
    }
  }, []);

  return (
    <StyledBackground
      Tag="div"
      fluid={data.background.localFile.childImageSharp.fluid}
    >
      <StyledFormWrapper
        ref={el => {
          container = el;
        }}
      >
        <Formik
          initialValues={{
            email: '',
            tel: '',
            fullName: '',
            companyName: '',
            message: '',
            rules: false,
          }}
          validationSchema={DisplayingErrorMessagesSchema}
          onSubmit={(values, { resetForm }) => {
            setSubmitting(true);
            setLoading(true);

            const fields = {
              email: values.email,
              tel: values.tel,
              fullName: values.fullName,
              companyName: values.companyName,
              message: values.message,
            };
            // eslint-disable-next-line no-undef
            fetch(`${process.env.GATSBY_API_URL}/contact-form`, {
              method: 'POST',
              body: JSON.stringify(fields),
              headers: {
                'Content-Type': 'application/json',
              },
            })
              .then(response => response.json())
              .then(content => {
                // eslint-disable-next-line
                if (parseInt(content.statusCode) === 200) {
                  setTimeout(() => {
                    setLoading(false);
                    setSuccess(true);
                    resetForm();
                    setTimeout(() => {
                      setSuccess(false);
                      setSubmitting(false);
                    }, 3000);
                  }, 3000);
                } else {
                  setTimeout(() => {
                    setLoading(false);
                    setSuccess(false);
                    setSubmitting(false);
                  }, 3000);
                  // eslint-disable-next-line no-console
                  console.error(content.error);
                }
              })
              .catch(error => {
                setTimeout(() => {
                  setLoading(false);
                  setSuccess(false);
                  setSubmitting(false);
                }, 3000);
                // eslint-disable-next-line no-console
                console.error(error);
              });
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            handleSubmit,
          }) => (
            <StyledFormInnerWrapper
              ref={el => {
                form = el;
              }}
            >
              <StyledForm blur={submitting} onSubmit={handleSubmit}>
                <StyledTitelWrapper>
                  <StyledTitle>{parse(data.title)}</StyledTitle>

                  <IntlContextConsumer>
                    {({ language: currentLocale }) =>
                      currentLocale === 'en' && (
                        <StyledSubtitle>
                          {intl.formatMessage({ id: 'form_contact_info' })}
                        </StyledSubtitle>
                      )
                    }
                  </IntlContextConsumer>
                </StyledTitelWrapper>

                <StyledInputRow>
                  <Input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder={intl.formatMessage({ id: 'form_label_email' })}
                    errorMessage={touched.email && errors.email}
                  />

                  <Input
                    type="tel"
                    name="tel"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tel}
                    placeholder={intl.formatMessage({ id: 'form_label_tel' })}
                    errorMessage={touched.tel && errors.tel}
                  />
                </StyledInputRow>

                <StyledInputRow>
                  <Input
                    type="text"
                    name="fullName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fullName}
                    placeholder={intl.formatMessage({
                      id: 'form_label_fullname',
                    })}
                    errorMessage={touched.fullName && errors.fullName}
                  />

                  <Input
                    type="text"
                    name="companyName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyName}
                    placeholder={intl.formatMessage({
                      id: 'form_label_company_name',
                    })}
                    errorMessage={touched.companyName && errors.companyName}
                  />
                </StyledInputRow>

                <Input
                  type="textarea"
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                  placeholder={intl.formatMessage({ id: 'form_label_message' })}
                  errorMessage={touched.message && errors.message}
                />

                <Checkbox
                  name="rules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.rules}
                  errorMessage={touched.rules && errors.rules}
                >
                  {intl.formatMessage({ id: 'form_label_rules.1' })}
                  <a href="mailto:info@holika.pl">
                    {intl.formatMessage({ id: 'form_label_rules.2' })}
                  </a>
                  {intl.formatMessage({ id: 'form_label_rules.3' })}
                  <br />
                  <br />
                  {intl.formatMessage({ id: 'form_label_rules.4' })}
                  <br />
                  <br />
                  {intl.formatMessage({ id: 'form_label_rules.5' })}
                  <br />
                  <br />
                  {intl.formatMessage({ id: 'form_label_rules.6' })}
                </Checkbox>

                <StyledSubmitBtn type="submit">
                  {intl.formatMessage({ id: 'form_submit' })}
                </StyledSubmitBtn>
              </StyledForm>

              {loading && <Spinner />}
              {success && <Success />}
            </StyledFormInnerWrapper>
          )}
        </Formik>
      </StyledFormWrapper>
    </StyledBackground>
  );
};

export default Form;
