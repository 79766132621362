import React, { useRef, useEffect } from 'react';
import { isBrowser } from 'react-device-detect';

import { aloesAnimation } from 'theme/Animations';
import parse from 'html-react-parser';

import {
  StyledBackground,
  StyledWrapper,
  StyledPanel,
  StyledTitle,
} from './ListWithBackgroundStyles';

const ListWithBackground = ({ data }) => {
  let container = useRef(null);
  let element1 = useRef(null);
  let element2 = useRef(null);
  let element3 = useRef(null);
  let element4 = useRef(null);
  let element5 = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      aloesAnimation(container, [
        element1,
        element2,
        element3,
        element4,
        element5,
      ]);
    }
  }, []);

  return (
    <StyledBackground
      Tag="div"
      fluid={data.background.localFile.childImageSharp.fluid}
      style={{
        backgroundPosition: '80% 50%',
      }}
    >
      <StyledWrapper
        ref={el => {
          container = el;
        }}
      >
        <StyledPanel
          ref={el => {
            element1 = el;
          }}
          $color={data.color}
        >
          <StyledTitle>{parse(data.list.items[0])}</StyledTitle>
        </StyledPanel>
        <StyledPanel
          ref={el => {
            element2 = el;
          }}
          $color={data.color}
        >
          <StyledTitle>{parse(data.list.items[1])}</StyledTitle>
        </StyledPanel>
        <StyledPanel
          ref={el => {
            element3 = el;
          }}
          $color={data.color}
        >
          <StyledTitle>{parse(data.list.items[2])}</StyledTitle>
        </StyledPanel>
        <StyledPanel
          ref={el => {
            element4 = el;
          }}
          $color={data.color}
        >
          <StyledTitle>{parse(data.list.items[3])}</StyledTitle>
        </StyledPanel>
        <StyledPanel
          ref={el => {
            element5 = el;
          }}
          $color={data.color}
        >
          <StyledTitle>{parse(data.list.items[4])}</StyledTitle>
        </StyledPanel>
      </StyledWrapper>
    </StyledBackground>
  );
};

export default ListWithBackground;
