import React, { useEffect, useRef } from 'react';
import 'swiper/swiper-bundle.css';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { isBrowser } from 'react-device-detect';
import { sliderAnimation } from 'theme/Animations';
import parse from 'html-react-parser';
import withLocale from 'hoc/withLocale';

import {
  StyledSectionWrapper,
  StyledHeading,
  StyledSwiper,
  StyledImg,
  StyledImgWrapper,
} from './TitleSliderStyles';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const sliderParams = {
  slidesPerView: 'auto',
  slidesPerGroup: 1,
  centeredSlides: true,
  spaceBetween: 16,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  loop: true,
  pagination: { clickable: true },
  navigation: {
    nextEl: '.swiper-button-next.slider-arrow',
    prevEl: '.swiper-button-prev.slider-arrow',
  },
};

const TitleSlider = ({ data, currentLocale }) => {
  let container = useRef(null);
  let heading = useRef(null);
  let slider = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      sliderAnimation(container, heading, slider);
    }
  }, []);

  return (
    <StyledSectionWrapper
      ref={el => {
        container = el;
      }}
    >
      <StyledHeading
        ref={el => {
          heading = el;
        }}
      >
        {parse(data.title)}
      </StyledHeading>

      <StyledSwiper
        ref={el => {
          slider = el;
        }}
      >
        <Swiper {...sliderParams}>
          {data?.images &&
            data.images.map((image, idx) => {
              const gatsbyImage = getImage(image.localFile);
              const alt = image.alternativeText ?? 'slider image';
              const bgImage = convertToBgImage(gatsbyImage);
              return (
                <SwiperSlide>
                  <StyledImgWrapper key={idx} alt={alt}>
                    <StyledImg
                      onClick={() => {
                        if (image.caption) {
                          const links = image.caption.split('|');
                          window.open(
                            currentLocale === 'en' && links[1]
                              ? links[1]
                              : links[0],
                            '_blank'
                          );
                        }
                      }}
                      Tag="div"
                      {...bgImage}
                      alt={alt}
                    />
                  </StyledImgWrapper>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </StyledSwiper>
    </StyledSectionWrapper>
  );
};

export default withLocale(TitleSlider);
