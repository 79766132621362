import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const StyledSectionWrapper = styled('div')`
  width: 100%;
  margin: 4rem 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 3rem;
  background: ${({ backgroundColor }) => backgroundColor ?? '#FFFFFF'};
  padding-bottom: 4rem;
  ${({ theme }) => theme.mq.md} {
    height: 100vh;
    justify-content: space-around;
    padding-bottom: 5rem;
    margin: 0;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding-bottom: 6rem;
  }
`;

export const StyledHeading = styled('h2')`
  font-size: 2rem;
  font-weight: 600;
  line-height: 3.5rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.redLight};
  text-align: center;
  ${({ theme }) => theme.mq.md} {
    font-size: 2.2rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 2.4rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.6rem;
  }
`;

export const StyledSwiper = styled('div')`
  width: 100vw;
  position: relative;
  z-index: 9;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60px;
    z-index: 9;
    ${({ theme }) => theme.mq.lg} {
      width: 80px;
    }
    ${({ theme }) => theme.mq.xxxl} {
      width: 120px;
    }
  }
  .slider-arrow {
    display: none;
    ${({ theme }) => theme.mq.md} {
      box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
      height: 45px;
      width: 45px;
      background-color: ${({ theme }) => theme.colors.white};
      z-index: 10;
      color: ${({ theme }) => theme.colors.black};
      &:after {
        font-size: 2rem;
      }
      ${({ theme }) => theme.mq.lg} {
        height: 56px;
        width: 56px;
      }
    }
  }
  .swiper-button-prev {
    left: 60px;
    ${({ theme }) => theme.mq.lg} {
      left: 80px;
    }
    ${({ theme }) => theme.mq.xxxl} {
      left: 120px;
    }
  }
  .swiper-button-next {
    right: 60px;
    ${({ theme }) => theme.mq.lg} {
      right: 80px;
    }
    ${({ theme }) => theme.mq.xxxl} {
      right: 120px;
    }
  }
  .swiper-pagination {
    ${({ theme }) => theme.mq.md} {
      display: none;
    }
  }
  .swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    transform: scale(1) !important;
    transition: 0.3s;
    &-active {
      background-color: hsla(66, 99%, 66%, 1) !important;
    }
  }
  .swiper-slide {
    overflow: hidden;
    height: 229px;
    width: 229px;
    transition: transform 0.3s;
    background-color: transparent;
    transform: scale(0.8) !important;
    &.swiper-slide-active {
      transform: scale(1) !important;
    }
    ${({ theme }) => theme.mq.md} {
      height: 32vw;
      width: 32vw;
    }
    ${({ theme }) => theme.mq.lg} {
      height: 27vw;
      width: 27vw;
    }
    ${({ theme }) => theme.mq.xxxl} {
      height: 23vw;
      width: 23vw;
    }
  }
`;

export const StyledImg = styled(BackgroundImage)`
  display: block;
  height: 100%;
  width: 100%;
`;

export const StyledImgWrapper = styled.p`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;

  height: 85%;
  width: 100%;

  padding-bottom: 2rem;

  ::after {
    height: 4rem;
    font-size: 1.2rem;
    ${({ theme }) => theme.mq.md} {
      height: auto;
      font-size: 1.6rem;
    }
    width: 80%;
    padding: 0 10%;
    text-align: center;
    position: absolute;
    bottom: -2rem;
    content: ${({ alt }) => `"${alt}"` ?? `""`};
  }
`;
