import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Element } from 'react-scroll';
import { injectIntl } from 'gatsby-plugin-intl';
import withLocale from 'hoc/withLocale';
import SEO from 'components/seo/seo';
import Layout from 'layouts/Layout';
import Header from 'sections/Header/Header';
import AboutUs from 'sections/AboutUs/AboutUs';
import HuecalmTemplate from 'sections/HuecalmTemplate/HuecalmTemplate';
import TitleSlider from 'sections/TitleSlider/TitleSlider';
import ThreeColumnsSection from 'sections/ThreeColumnsSection/ThreeColumnsSection';
import ListWithBackground from 'sections/ListWithBackground/ListWithBackground';
import Form from 'sections/Form/Form';
import Footer from 'components/Footer/Footer';
import { TemplateEnum } from '../enums/templates';

const Scroll = require('react-scroll');

const { scroller } = Scroll;

const IndexPage = props => {
  const { currentLocale } = props;
  const data = useStaticQuery(
    graphql`
      query {
        metaPl: strapiHomepageMetatags(locale: { eq: "pl" }) {
          SEO {
            meta_title
            meta_description
            image_alt
            meta_image {
              localFile {
                url
              }
            }
          }
        }
        metaEn: strapiHomepageMetatags(locale: { eq: "en" }) {
          SEO {
            meta_title
            meta_description
            image_alt
            meta_image {
              localFile {
                url
              }
            }
          }
        }
        orderPl: allStrapiSectionsOrder(filter: { locale: { eq: "pl" } }) {
          nodes {
            order {
              all_section {
                id
                name
                hash
              }
            }
          }
        }
        orderEn: allStrapiSectionsOrder(filter: { locale: { eq: "en" } }) {
          nodes {
            order {
              all_section {
                id
                name
                hash
              }
            }
          }
        }
        dataPl: allStrapiAllSectionsExt(filter: { locale: { eq: "pl" } }) {
          edges {
            node {
              strapiId
              sectionsJSON {
                _0 {
                  title
                  title2
                  title3
                  text
                  text2
                  text3
                  link
                  list {
                    items
                  }
                  color
                  backgroundColor
                  _xtypename
                  images {
                    alternativeText
                    caption
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  background {
                    alternativeText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        dataEn: allStrapiAllSectionsExt(filter: { locale: { eq: "en" } }) {
          edges {
            node {
              strapiId
              sectionsJSON {
                _0 {
                  title
                  title2
                  title3
                  text
                  text2
                  text3
                  link
                  list {
                    items
                  }
                  color
                  backgroundColor
                  _xtypename
                  images {
                    alternativeText
                    caption
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  background {
                    alternativeText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  let meta;
  let order;
  let allStrapiData;
  if (currentLocale === 'pl') {
    meta = data.metaPl;
    order = data.orderPl.nodes[0].order;
    allStrapiData = data.dataPl.edges;
  } else if (currentLocale === 'en') {
    meta = data.metaEn;
    order = data.orderEn.nodes[0].order;
    allStrapiData = data.dataEn.edges;
  }

  const {
    meta_title: metaTitle,
    meta_description: metaDescription,
    image_alt: imgAlt,
    meta_image: {
      localFile: { url },
    },
  } = meta.SEO;

  const getSection = () =>
    order.map((item, idx) => {
      if (!item || !Object.keys(item).length) {
        return <></>;
      }

      let sectionsData = allStrapiData.find(
        strapiData => strapiData.node.strapiId === item.all_section.id
      );

      if (!sectionsData) {
        return <></>;
      }

      sectionsData = sectionsData.node.sectionsJSON._0;

      const type = sectionsData._xtypename;
      const { name } = item.all_section;
      const hash = item.all_section.hash
        ? `#${item.all_section.hash}`
        : undefined;

      if (type === TemplateEnum.AboutUs) {
        return (
          <Element name={hash ?? name} key={idx}>
            <AboutUs data={sectionsData} />
          </Element>
        );
      }
      if (type === TemplateEnum.HuecalmTemplate) {
        return (
          <Element name={hash ?? name} key={idx}>
            <HuecalmTemplate data={sectionsData} />
          </Element>
        );
      }
      if (type === TemplateEnum.TitleSlider) {
        return (
          <Element name={hash ?? name} key={idx}>
            <TitleSlider data={sectionsData} currentLocale={currentLocale} />
          </Element>
        );
      }
      if (type === TemplateEnum.ThreeColumnsSection) {
        return (
          <Element name={hash ?? name} key={idx}>
            <ThreeColumnsSection data={sectionsData} />
          </Element>
        );
      }
      if (type === TemplateEnum.ListWithBackground) {
        return (
          <Element name={hash ?? name} key={idx}>
            <ListWithBackground data={sectionsData} />
          </Element>
        );
      }
      if (type === TemplateEnum.ContactForm) {
        return (
          <Element name={hash ?? name} key={idx}>
            <Form data={sectionsData} />
          </Element>
        );
      }

      return <></>;
    });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { hash } = props.location;

      if (hash) {
        scroller.scrollTo(hash, {
          duration: 1000,
          delay: 1000,
          smooth: true,
        });
      }
    }
  }, []);

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        image={url}
        imgAlt={imgAlt}
      />
      <Element name="Home">
        <Header />
      </Element>
      {getSection()}
      <Element name="navigation_footer">
        <Footer />
      </Element>
    </Layout>
  );
};

export default injectIntl(withLocale(IndexPage));
