import React from 'react';
import parse from 'html-react-parser';

import {
  StyledBackground,
  StyledContainer,
  StyledWrapper,
  StyledContent,
  StyledTitle,
  StyledParagraph,
  StyledHashtag,
} from './AboutUsStyles';

const AboutUs = ({ data }) => (
  <StyledBackground
    Tag="div"
    fluid={data.background.localFile.childImageSharp.fluid}
  >
    <StyledContainer>
      <StyledWrapper>
        <StyledContent>
          <StyledTitle>{parse(data.title)}</StyledTitle>
          <StyledParagraph>{parse(data.text)}</StyledParagraph>
          <StyledHashtag>{parse(data.text2)}</StyledHashtag>
        </StyledContent>
      </StyledWrapper>
    </StyledContainer>
  </StyledBackground>
);

export default AboutUs;
